import React, { useEffect, useState } from "react";
import {
  Stack,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Chip,
  FormHelperText,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  Modal,
  Empty,
  Descriptions,
  Radio,
  ConfigProvider,
  Image,
  Menu,
  Avatar,
  Card,
  Input,
  Badge,
  Select as Select1,
  Tooltip as AntTooltip,
  Typography as AntTypography,
  Pagination,
  List,
  Popconfirm,
  Divider,
} from "antd";

import {
  add_action_name,
  add_machine_action_details,
  get_action_name,
  get_all_machineDetails,
  get_api_function,
  get_checklist_attribute_status,
  get_final_checklist_details,
  get_machine_checklist,
  get_spare_detail_by_ticket,
  get_spare_details_by_machine,
  get_spare_part_inventory,
  maintenace_ticket_use,
  save_checklist_action_details,
  save_checklist_value,
  save_final_checklist_details,
  save_machine_time_record,
  update_checklist_details,
  update_maintenace_ticket_status,
} from "utils/api";
import { useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getBase64, loading_image } from "../ReusableFunctions";
dayjs.extend(utc);
const { Meta } = Card;

const addKeysInFilterDataForCollapse = (filterData) => {
  let keyCounter = 0; // Initialize key counter

  const groupedData = filterData.reduce((acc, item) => {
    const sectionId = item.section_id || "default";
    const sectionName = item.section_name || "Default Section";

    if (!acc[sectionId]) {
      acc[sectionId] = {
        key: keyCounter.toString(),
        section_id: sectionId,
        section_name: sectionName,
        attributes: [],
      };
      keyCounter++;
    }
    const { section_id, section_name, ...attribute } = item;
    attribute?.hasOwnProperty("attributes")
      ? (acc[sectionId].attributes = attribute?.attributes)
      : acc[sectionId].attributes.push(attribute);
    return acc;
  }, {});

  const updatedFilterList = Object.values(groupedData).map((item) => ({
    ...item,
    key: item.key,
    attributes: item.attributes,
  }));

  return updatedFilterList;
};

function getRecordValue(record) {
  if (!record?.data_type) return null;
  const dataType = record.data_type.toLowerCase();
  return dataType === "string"
    ? record.value
    : dataType === "integer"
    ? `${record.min_value} - ${record.max_value}`
    : dataType === "boolean"
    ? `${record.bool_value}`
    : null;
}

function TicketCard({
  get_status,
  ticket_id,
  machine_id,
  ticket_status,
  complete_status,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");

  const handle_add_name = (e) => {
    set_add_name(e.target.value);
  };

  const handle_submit_add_name = () => {
    if (add_index === "1") {
      add_action_name(success_config, {
        action_name: add_name,
      });
    }
  };

  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (add_index === "1") {
        get_action_name(success_get_action_name);
      }
      setIsModalOpen(false);
      set_add_name("");
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    set_add_name("");
    set_add_index("");
  };

  // -----------------------------------------------------------------------

  const navigate = useNavigate();
  const [value_list, set_value_list] = useState([]);
  const [section_details, set_section_details] = useState([]);
  const [attribute_details, set_attribute_details] = useState([]);
  const [page_total, set_page_total] = useState(0);
  const [page_number, set_page_number] = useState(1);
  const [default_open_section, set_default_open_section] = useState();
  const [action_name_list, set_action_name_list] = useState([]);
  const [action, set_action] = useState([]);
  const [used_spare_list, set_used_spare_list] = useState([]);

  useEffect(() => {
    get_action_name(success_get_action_name);
    get_final_checklist_details(success_get_final_checklist_details, {
      machine_id: machine_id,
      ticket_id: ticket_id,
    });
    get_spare_details_by_machine(success_get_spare_part_inventory, {
      machine_id: machine_id,
    });
    get_spare_detail_by_ticket(success_get_spare_detail_by_ticket, {
      ticket_id: ticket_id,
    });
  }, [machine_id, ticket_id]);

  const set_value_action_function = (res) => {
    set_value_list(res.data.data?.map(() => null));
    set_action(res.data.data?.map(() => null));
  };

  const success_get_final_checklist_details = (res) => {
    res?.data?.data?.length !== 0 &&
      get_api_function(
        "/get_checklist_sections_details",
        success_get_checklist,
        {
          machine_id: machine_id,
          ticket_id: ticket_id,
          section_id:
            res.data.data?.sort((a, b) => a.section_id - b.section_id)[0]
              ?.section_id === "Default"
              ? ""
              : res.data.data?.sort((a, b) => a.section_id - b.section_id)[0]
                  ?.section_id,
          page_number: page_number,
        }
      );
    set_section_details(
      res.data.data?.sort((a, b) => a.section_id - b.section_id)
    );
    set_default_open_section(
      String(
        res.data.data?.sort((a, b) => a.section_id - b.section_id)[0]
          ?.section_id
      )
    );
    res?.data?.data?.length === 0 &&
      save_final_checklist_details(success, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    set_value_action_function(res);
  };

  const success_get_final_checklist_details1 = (res) => {
    set_section_details(
      res.data.data?.sort((a, b) => a.section_id - b.section_id)
    );
  };

  const success_get_checklist = (res) => {
    set_attribute_details(res.data.data);
    set_page_total(res?.data?.obj_count);
    set_value_action_function(res);
  };

  const success_get_action_name = (res) => {
    set_action_name_list(res.data.data);
  };

  const handleAction = (e, index) => {
    const temp = [...action];
    temp[index] = e;
    set_action(temp);
  };

  const handleValue = (value, index) => {
    const temp = [...value_list];
    temp[index] = value;
    set_value_list(temp);
  };

  const success = async (res) => {
    if (res.status === "success") {
      get_final_checklist_details(success_get_final_checklist_details, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    } else if (res.status === "failed") {
      toast.warning("There are no attributes associated with machine");
    }
  };

  const success1 = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_api_function(
        "/get_checklist_sections_details",
        success_get_checklist,
        {
          machine_id: machine_id,
          ticket_id: ticket_id,
          section_id:
            default_open_section === "Default" ? "" : default_open_section,
          page_number: page_number,
        }
      );

      get_final_checklist_details(success_get_final_checklist_details1, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    } else if (res.status === "failed") {
      toast.warning("There are no attributes associated with machine");
    }
  };

  const success_ticket_status_update = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      handlePause();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const success_add_machine_action_details = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_final_checklist_details(success_get_final_checklist_details, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const handlePause = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(
      () => {
        window.location.reload();
      },
      {
        ticket_id: ticket_id,
        machine_id: machine_id,
        end_time: time,
      }
    );
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    console?.log(file);
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file?.originFileObj);
    }
    console.log(file?.preview);
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
    );
  };

  const [spare_use, set_spare_use] = useState([]);
  const [spare_parts_list, set_spare_parts_list] = useState([]);
  const success_get_spare_part_inventory = (res) => {
    set_spare_parts_list(res.data.data);
  };

  const success_get_spare_detail_by_ticket = (res) => {
    set_used_spare_list(res.data.data);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal = () => {
    setIsModalOpen2(true);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const [spare_part, set_spare_part] = useState("");
  const [quantity, set_quantity] = useState("");
  const handle_spare_part = (e) => {
    set_spare_part(e.target.value);
  };
  const [error, set_error] = useState(false);
  const handle_quantity = (e) => {
    if (
      e.target.value <=
      spare_parts_list?.find((item) => item.spare_part_id === spare_part)
        ?.spare_quantity
    ) {
      if (parseInt(e.target.value) === 0) {
        set_error("Quantity cannot be 0");
      } else {
        set_quantity(parseInt(e.target.value));
        set_error("");
      }
    } else {
      set_error("Quantity exceeds available inventory spare quantity");
    }
  };
  const handle_spare_use = (e) => {
    e.preventDefault();
    const temp = [...spare_use];
    temp.push({
      spare_inventry_id: spare_part,
      quantity: quantity,
    });
    set_spare_use(temp);
    handleCancel2();
  };

  function filterSpareUse(spare_parts_list, spare_use) {
    var usedSparePartIds = new Set(
      spare_use.map(function (use) {
        return use.spare_part_id;
      })
    );
    return spare_parts_list.filter(function (sparePart) {
      return !usedSparePartIds.has(sparePart.spare_part_id);
    });
  }

  const handleSelect = (key) => {
    set_default_open_section(key);
    set_attribute_details([1]);
    if (key !== "spare_parts") {
      get_api_function(
        "/get_checklist_sections_details",
        success_get_checklist,
        {
          machine_id: machine_id,
          ticket_id: ticket_id,
          section_id: key === "Default" ? "" : key,
          page_number: 1,
        }
      );
    }
  };

  return (
    <React.Fragment>
      {previewImage && (
        <ConfigProvider
          theme={{
            components: {
              Image: {
                zIndexPopup: 2000,
              },
            },
          }}
        >
          <Image
            wrapperStyle={{
              display: "none",
              height: "100%",
            }}
            style={{ height: "100%" }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              // afterOpenChange: (visible) =>
              //   !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        </ConfigProvider>
      )}
      <Grid container>
        <Grid item lg={3}>
          <Stack direction="row">
            <List
              bordered
              style={{
                width: "100%",
              }}
              dataSource={[
                ...section_details,
                { section_id: "spare_parts", section_name: "Spare Parts" },
              ]}
              renderItem={(item) => (
                <List.Item
                  onClick={() => handleSelect(item.section_id)}
                  style={{
                    cursor: "pointer",
                    paddingRight: 5,
                    background:
                      default_open_section === item.section_id
                        ? "#e6f7ff"
                        : "transparent",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Tooltip title={item.section_name}>
                      <AntTypography>{item.section_name}</AntTypography>
                    </Tooltip>
                    <Stack spacing={0.2} direction="row">
                      {parseInt(item?.completed) > 0 && (
                        <Badge
                          title={`Completed - ${item?.completed}`}
                          count={parseInt(item?.completed)}
                          color="green"
                        />
                      )}
                      {parseInt(item?.total - item?.completed) > 0 && (
                        <Badge
                          title={`Pending - ${item?.total - item?.completed}`}
                          count={parseInt(item?.total - item?.completed)}
                          color="yellow"
                        />
                      )}
                    </Stack>
                  </Stack>
                </List.Item>
              )}
            />
            <Divider type="vertical" style={{ height: "inherit" }} />
          </Stack>
        </Grid>
        <Grid item container lg={9} rowSpacing={2}>
          {/* {default_open_section !== "spare_parts" &&
            attribute_details?.map((spec_json, index) => (
              <Grid
                item
                lg={4}
                container
                direction="row"
                justifyContent="center"
              >
                <Card
                  style={{
                    width: 275,
                    height: "fit-content",
                  }}
                  bodyStyle={{ padding: 10 }}
                  cover={
                    spec_json?.image_status && spec_json?.image ? (
                      <Image
                        style={{ width: "100%", height: "150px" }}
                        src={`data:image/png;base64,${spec_json?.image}`}
                      />
                    ) : (
                      <></>
                    )
                  }
                  actions={
                    spec_json?.user_value_status === false &&
                    !spec_json?.action_id
                      ? [
                          <Tooltip title="Resolved">
                            <IconButton
                              size="small"
                              disabled={action[index] === ""}
                              onClick={() => {
                                save_checklist_action_details(success1, {
                                  machine_id: machine_id,
                                  ticket_id: ticket_id,
                                  action_id: action[index],
                                  action_status: true,
                                  attribute_value_id:
                                    spec_json?.attribute_value_id,
                                });
                              }}
                            >
                              <CheckIcon
                                style={{
                                  color: "#00C853",
                                  fontSize: "28px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>,
                          <Tooltip title="Not Resolved">
                            <IconButton
                              size="small"
                              disabled={action[index] === ""}
                              onClick={() => {
                                save_checklist_action_details(success1, {
                                  machine_id: machine_id,
                                  ticket_id: ticket_id,
                                  action_id: action[index],
                                  action_status: false,
                                  attribute_value_id:
                                    spec_json?.attribute_value_id,
                                });
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: "red",
                                  fontSize: "28px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>,
                        ]
                      : typeof spec_json?.user_value_status !== "boolean" && [
                          <Tooltip title="Okay">
                            <IconButton
                              size="small"
                              // disabled={value_list[index].length === 0}
                              onClick={() => {
                                update_checklist_details(success1, {
                                  attribute_value_id:
                                    spec_json?.attribute_value_id,
                                  machine_id: machine_id,
                                  ticket_id: ticket_id,
                                  user_value: value_list[index],
                                  user_value_status: true,
                                });
                              }}
                            >
                              <CheckIcon
                                style={{
                                  color: "#00C853",
                                  fontSize: "28px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>,
                          <Tooltip title="Not Okay">
                            <IconButton
                              size="small"
                              // disabled={value_list[index].length === 0}
                              onClick={() => {
                                update_checklist_details(success1, {
                                  attribute_value_id:
                                    spec_json?.attribute_value_id,
                                  machine_id: machine_id,
                                  ticket_id: ticket_id,
                                  user_value: value_list[index],
                                  user_value_status: false,
                                });
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: "red",
                                  fontSize: "28px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>,
                        ]
                  }
                >
                  <Meta
                    title={
                      <AntTypography
                        level={5}
                        ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      >
                        {spec_json?.attribute_name +
                          " " +
                          (spec_json?.unit ? `(${spec_json?.unit})` : "") +
                          " : " +
                          getRecordValue(spec_json)}
                      </AntTypography>
                    }
                    description={
                      <Stack>
                        <Descriptions
                          size="small"
                          column={1}
                          // layout="vertical"
                          colon={true}
                          items={[
                            spec_json?.user_value_status !== null && {
                              key: "1",
                              label: "Value",
                              children: (
                                <AntTooltip
                                  title={
                                    spec_json?.user_value_status
                                      ? "Okay"
                                      : "Not Okay"
                                  }
                                  color={
                                    spec_json?.user_value_status
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  <Badge
                                    color={
                                      spec_json?.user_value_status
                                        ? "green"
                                        : "red"
                                    }
                                    text={spec_json?.user_value}
                                  />
                                </AntTooltip>
                              ),
                            },
                            spec_json?.action_name && {
                              key: "2",
                              label: "Action",
                              children: (
                                <AntTooltip
                                  title={
                                    spec_json?.action_status
                                      ? "Resolved"
                                      : "Not Resolved"
                                  }
                                  color={
                                    spec_json?.action_status ? "green" : "red"
                                  }
                                >
                                  <Badge
                                    color={
                                      spec_json?.action_status ? "green" : "red"
                                    }
                                    text={spec_json?.action_name}
                                  />
                                </AntTooltip>
                              ),
                            },
                          ]?.filter(Boolean)}
                        />
                        {spec_json?.user_value === "" &&
                          spec_json?.user_value_status === null &&
                          (spec_json?.data_type === "Boolean" ||
                          spec_json?.data_type === "boolean" ? (
                            <Radio.Group
                              onChange={({ target: { value } }) => {
                                handleValue(value, index);
                              }}
                            >
                              <Radio value="true">True</Radio>
                              <Radio value="false">False</Radio>
                            </Radio.Group>
                          ) : (
                            <Input
                              id="value"
                              type="text"
                              placeholder="Enter Value"
                              value={value_list[index]}
                              onChange={(e) => {
                                handleValue(e?.target?.value, index);
                              }}
                              fullWidth
                            />
                          ))}
                        {spec_json?.user_value_status === false &&
                          spec_json?.action_name === "" && (
                            <Stack
                              direction="row"
                              alignItem="center"
                              spacing={1}
                              sx={{ width: "100%" }}
                            >
                              <Select1
                                showSearch
                                id="action"
                                allowClear
                                placeholder="Select Action"
                                optionFilterProp="children"
                                value={action[index] || null}
                                onChange={(e) => {
                                  handleAction(e, index);
                                }}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{
                                  width: "180px",
                                  border: "0.5px solid #D9D9D9",
                                  borderRadius: "3px",
                                }}
                                bordered={false}
                                dropdownStyle={{
                                  maxHeight: 400,
                                  width: 400,
                                  overflow: "auto",
                                }}
                                options={action_name_list?.map((item) => {
                                  return {
                                    value: item?.action_id,
                                    label: item?.action_name,
                                  };
                                })}
                              />

                              <Tooltip title="Add Action">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    set_add_index("1");
                                  }}
                                >
                                  <AddIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                      </Stack>
                    }
                  />
                </Card>
              </Grid>
            ))} */}
          <Grid itm lg={12}>
            {/* ------------------------------------------------------- */}

            <List
              itemLayout="vertical"
              style={{ padding: "0 12px" }}
              dataSource={attribute_details}
              renderItem={(spec_json, index) => {
                return default_open_section === "spare_parts" ? (
                  <List.Item
                    style={{
                      minHeight: "120px",
                      border: "2px solid #E6EBF1",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <Grid item lg={12} container spacing={2} sx={{ mx: 1 }}>
                      {used_spare_list?.length === 0 ? (
                        ![
                          "Completed",
                          "Closed",
                          "Verified",
                          "Resolved",
                        ].includes(ticket_status) ? (
                          <Grid item lg={12}>
                            {spare_use?.map((item) => (
                              <Chip
                                sx={{ m: 0.5 }}
                                label={`${
                                  spare_parts_list?.find(
                                    (spare) =>
                                      spare.spare_part_id ===
                                      item?.spare_inventry_id
                                  )?.spare_part_name
                                }
                       - ${item?.quantity}`}
                                variant="outlined"
                                onDelete={() => {
                                  const temp = [...spare_use];
                                  const temp2 = temp.filter(
                                    (obj) =>
                                      obj.spare_inventry_id !==
                                      item?.spare_inventry_id
                                  );
                                  set_spare_use(temp2);
                                }}
                              />
                            ))}

                            <Button
                              size="small"
                              onClick={showModal}
                              variant="contained"
                              sx={{ height: "fit-content" }}
                            >
                              Add Spare Part
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item lg={12}>
                            <AntTypography.Title level={4} type="secondary">
                              No Spare Parts Used
                            </AntTypography.Title>
                          </Grid>
                        )
                      ) : (
                        <Grid item lg={12}>
                          {used_spare_list?.map((item) => (
                            <Chip
                              sx={{ m: 0.5 }}
                              label={`${item?.spare_name}
                       - ${item?.spare_quantity}`}
                              variant="outlined"
                            />
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </List.Item>
                ) : (
                  <List.Item
                    key={spec_json?.attribute_value_id}
                    style={{
                      minHeight: "120px",
                      border: "2px solid #E6EBF1",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "10px 0",
                    }}
                    extra={
                      spec_json?.image_status && spec_json?.image ? (
                        <Stack
                          sx={{ height: "100%" }}
                          direction="row"
                          alignItems="center"
                        >
                          <Divider type="vertical" style={{ height: "100%" }} />
                          <Image
                            style={{ width: "150px", height: "100px" }}
                            src={`data:image/png;base64,${spec_json?.image}`}
                          />
                        </Stack>
                      ) : (
                        <div
                          style={{
                            width: "150px",
                          }}
                        ></div>
                      )
                    }
                    actions={[]}
                  >
                    <Grid container sx={{ height: "100%" }} columnSpacing={1}>
                      <Grid
                        item
                        container
                        lg={7.75}
                        justifyContent="center"
                        direction="column"
                        gap={1}
                      >
                        <AntTypography.Title
                          level={5}
                          ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: "more",
                          }}
                          style={{
                            maxWidth: "100%",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            margin: 0,
                          }}
                        >
                          {spec_json?.attribute_name}
                        </AntTypography.Title>
                        {["integer", "Integer"]?.includes(
                          spec_json?.data_type
                        ) && (
                          <AntTypography.Text
                            type="secondary"
                            ellipsis={{
                              rows: 1,
                              expandable: true,
                              symbol: "more",
                            }}
                          >
                            {getRecordValue(spec_json) +
                              " " +
                              (spec_json?.unit ? `(${spec_json?.unit})` : "")}
                          </AntTypography.Text>
                        )}
                      </Grid>
                      <Grid item lg={0.25}>
                        <Divider type="vertical" style={{ height: "100%" }} />
                      </Grid>
                      <Grid item lg={4}>
                        <Stack
                          justifyContent="center"
                          sx={{ height: "100%" }}
                          spacing={1}
                        >
                          <Descriptions
                            size="small"
                            column={1}
                            // layout="vertical"
                            colon={true}
                            items={[
                              spec_json?.user_value_status !== null && {
                                key: "1",
                                label: "Value",
                                children: (
                                  <AntTooltip
                                    title={
                                      spec_json?.user_value_status
                                        ? "Okay"
                                        : "Not Okay"
                                    }
                                    color={
                                      spec_json?.user_value_status
                                        ? "green"
                                        : "red"
                                    }
                                  >
                                    <Badge
                                      color={
                                        spec_json?.user_value_status
                                          ? "green"
                                          : "red"
                                      }
                                      text={spec_json?.user_value}
                                    />
                                  </AntTooltip>
                                ),
                              },
                              spec_json?.action_name && {
                                key: "2",
                                label: "Action",
                                children: (
                                  <AntTooltip
                                    title={
                                      spec_json?.action_status
                                        ? "Resolved"
                                        : "Not Resolved"
                                    }
                                    color={
                                      spec_json?.action_status ? "green" : "red"
                                    }
                                  >
                                    <Badge
                                      color={
                                        spec_json?.action_status
                                          ? "green"
                                          : "red"
                                      }
                                      text={spec_json?.action_name}
                                    />
                                  </AntTooltip>
                                ),
                              },
                            ]?.filter(Boolean)}
                          />
                          {complete_status &&
                            spec_json?.user_value === "" &&
                            spec_json?.user_value_status === null &&
                            (spec_json?.data_type === "Boolean" ||
                            spec_json?.data_type === "boolean" ? (
                              <Radio.Group
                                onChange={({ target: { value } }) => {
                                  handleValue(value, index);
                                }}
                              >
                                <Radio value="true">True</Radio>
                                <Radio value="false">False</Radio>
                              </Radio.Group>
                            ) : spec_json?.data_type === "List" ? (
                              <Select1
                                showSearch
                                id="action"
                                allowClear
                                placeholder="Select Value"
                                optionFilterProp="children"
                                value={value_list[index]}
                                onChange={(value) => {
                                  handleValue(value, index);
                                }}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{
                                  width: "100%",
                                  border: "0.5px solid #D9D9D9",
                                  borderRadius: "3px",
                                }}
                                bordered={false}
                                dropdownStyle={{
                                  maxHeight: 400,
                                  width: 400,
                                  overflow: "auto",
                                }}
                                options={spec_json?.list
                                  ?.split(",")
                                  ?.map((item) => {
                                    return {
                                      value: item,
                                      label: item,
                                    };
                                  })}
                              />
                            ) : (
                              <Input
                                id="value"
                                type="text"
                                placeholder="Enter Value"
                                value={value_list[index]}
                                onChange={(e) => {
                                  handleValue(e?.target?.value, index);
                                }}
                                fullWidth
                              />
                            ))}
                          {complete_status &&
                            spec_json?.user_value_status === false &&
                            spec_json?.action_name === "" && (
                              <Stack
                                direction="row"
                                alignItem="center"
                                spacing={1}
                                sx={{ width: "100%" }}
                              >
                                <Select1
                                  showSearch
                                  id="action"
                                  allowClear
                                  placeholder="Select Action"
                                  optionFilterProp="children"
                                  value={action[index] || null}
                                  onChange={(e) => {
                                    handleAction(e, index);
                                  }}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{
                                    width: "100%",
                                    border: "0.5px solid #D9D9D9",
                                    borderRadius: "3px",
                                  }}
                                  bordered={false}
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    width: 400,
                                    overflow: "auto",
                                  }}
                                  options={action_name_list?.map((item) => {
                                    return {
                                      value: item?.action_id,
                                      label: item?.action_name,
                                    };
                                  })}
                                />

                                <Tooltip title="Add Action">
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      set_add_index("1");
                                    }}
                                  >
                                    <AddIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            )}
                          {spec_json?.user_value_status === false &&
                          !spec_json?.action_id &&
                          complete_status ? (
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Resolved">
                                <IconButton
                                  size="small"
                                  disabled={action[index] === ""}
                                  onClick={() => {
                                    save_checklist_action_details(success1, {
                                      machine_id: machine_id,
                                      ticket_id: ticket_id,
                                      action_id: action[index],
                                      action_status: true,
                                      attribute_value_id:
                                        spec_json?.attribute_value_id,
                                    });
                                  }}
                                >
                                  <CheckIcon
                                    style={{
                                      color: "#00C853",
                                      fontSize: "28px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Not Resolved">
                                <IconButton
                                  size="small"
                                  disabled={action[index] === ""}
                                  onClick={() => {
                                    save_checklist_action_details(success1, {
                                      machine_id: machine_id,
                                      ticket_id: ticket_id,
                                      action_id: action[index],
                                      action_status: false,
                                      attribute_value_id:
                                        spec_json?.attribute_value_id,
                                    });
                                  }}
                                >
                                  <CloseIcon
                                    style={{
                                      color: "red",
                                      fontSize: "28px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          ) : (
                            typeof spec_json?.user_value_status !== "boolean" &&
                            complete_status && (
                              <Stack direction="row" spacing={1}>
                                <Tooltip title="Okay">
                                  <IconButton
                                    size="small"
                                    // disabled={value_list[index].length === 0}
                                    onClick={() => {
                                      update_checklist_details(success1, {
                                        attribute_value_id:
                                          spec_json?.attribute_value_id,
                                        machine_id: machine_id,
                                        ticket_id: ticket_id,
                                        user_value: value_list[index],
                                        user_value_status: true,
                                      });
                                    }}
                                  >
                                    <CheckIcon
                                      style={{
                                        color: "#00C853",
                                        fontSize: "28px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Not Okay">
                                  <IconButton
                                    size="small"
                                    // disabled={value_list[index].length === 0}
                                    onClick={() => {
                                      update_checklist_details(success1, {
                                        attribute_value_id:
                                          spec_json?.attribute_value_id,
                                        machine_id: machine_id,
                                        ticket_id: ticket_id,
                                        user_value: value_list[index],
                                        user_value_status: false,
                                      });
                                    }}
                                  >
                                    <CloseIcon
                                      style={{
                                        color: "red",
                                        fontSize: "28px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            )
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </List.Item>
                );
              }}
            />

            {/* ------------------------------------------------------- */}
          </Grid>

          {default_open_section !== "spare_parts" && (
            <Grid item lg={12}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Pagination
                  pageSize={6}
                  total={page_total}
                  onChange={(page) => {
                    get_api_function(
                      "/get_checklist_sections_details",
                      success_get_checklist,
                      {
                        machine_id: machine_id,
                        ticket_id: ticket_id,
                        section_id: default_open_section,
                        page_number: page,
                      }
                    );
                  }}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
      {window.location.hash === "#/maintenance-data" && get_status && (
        <Grid item lg={12}>
          <Stack direction="row" justifyContent="end" sx={{ my: 2 }}>
            <Popconfirm
              title="Submit PM Checklist"
              description="Confirm submission of your PM checklist?"
              onConfirm={() => {
                update_maintenace_ticket_status(success_ticket_status_update, {
                  machine_id: machine_id,
                  ticket_id: ticket_id,
                });
                maintenace_ticket_use(success_ticket_status_update, {
                  machine_id: machine_id,
                  ticket_id: ticket_id,
                  spare_list: spare_use,
                });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                variant="contained"
                // disabled={
                //   attribute_details?.some((obj) =>
                //     obj.hasOwnProperty("section_name")
                //   )
                //     ? addKeysInFilterDataForCollapse(attribute_details)?.some(
                //         (section) =>
                //           section?.attributes?.some(
                //             (attribute) => attribute.user_value_status === null
                //           )
                //       )
                //     : attribute_details?.some(
                //         (item) => item?.user_value_status === null
                //       )
                // }
              >
                Submit
              </Button>
            </Popconfirm>
          </Stack>
        </Grid>
      )}

      <Modal
        title={`Add ${add_index === "1" && "Action"}`}
        open={isModalOpen}
        okButtonProps={{ disabled: add_name.length === 0 }}
        onOk={handle_submit_add_name}
        onCancel={handleCancel}
        okText="Add"
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            <InputLabel id="name">
              {`${add_index === "1" && "Action"} : `}
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={6}>
            <OutlinedInput
              id="name"
              type="text"
              name="text"
              size="small"
              placeholder={`Enter ${add_index === "1" && "Action"}`}
              fullWidth
              required
              value={add_name}
              onChange={handle_add_name}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title="Spare Parts Used"
        open={isModalOpen2}
        onCancel={handleCancel2}
        footer={[]}
        afterClose={() => {
          set_spare_part("");
          set_quantity("");
        }}
      >
        <form onSubmit={handle_spare_use}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required id="spare_part">
                  Spare Part
                </InputLabel>
                <Select
                  labelId="spare_part"
                  onChange={handle_spare_part}
                  value={spare_part}
                  required
                  MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
                >
                  {filterSpareUse(spare_parts_list, spare_use)?.map((item) => {
                    return (
                      <MenuItem value={item?.spare_part_id}>
                        {item?.spare_part_name} &nbsp; - &nbsp;
                        {item?.manufacturer_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Grid>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required htmlFor="quantity">
                  Quantity
                </InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="number"
                  name="quantity"
                  placeholder="Enter quantity"
                  fullWidth
                  required
                  value={quantity}
                  onChange={handle_quantity}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                        },
                    },
                  }}
                />
                {error?.length > 0 && (
                  <FormHelperText error={error?.length > 0}>
                    {error}
                  </FormHelperText>
                )}
                {spare_part && (
                  <FormHelperText>
                    Available Inventory Spare Quantity:{" "}
                    {
                      spare_parts_list?.find(
                        (item) => item.spare_part_id === spare_part
                      )?.spare_quantity
                    }
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item lg={12} spacing={2}>
              <Stack spacing={2} direction="row" justifyContent="end">
                <Button variant="outlined" size="small" onClick={handleCancel2}>
                  Cancel
                </Button>
                <Button variant="contained" size="small" type="submit">
                  Add
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default TicketCard;

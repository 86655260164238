import React, { useEffect, useState } from "react";
import {
  Stack,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Chip,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  Modal,
  Empty,
  Descriptions,
  Radio,
  ConfigProvider,
  Image,
} from "antd";
import MainCard from "components/MainCard";
import {
  add_action_name,
  add_machine_action_details,
  get_action_name,
  get_all_machineDetails,
  get_api_function,
  get_attribute_details_for_machine,
  get_checklist_attribute_status,
  get_final_checklist_details,
  get_machine_checklist,
  get_spare_detail_by_ticket,
  get_spare_details_by_machine,
  get_spare_part_inventory,
  maintenace_ticket_use,
  save_checklist_action_details,
  save_checklist_value,
  save_final_checklist_details,
  save_machine_time_record,
  update_checklist_details,
  update_maintenace_ticket_status,
} from "utils/api";
import { useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getBase64, loading_image } from "../ReusableFunctions";
dayjs.extend(utc);

const addKeysInFilterDataForCollapse = (filterData) => {
  let keyCounter = 0; // Initialize key counter

  const groupedData = filterData.reduce((acc, item) => {
    const sectionId = item.section_id || "default";
    const sectionName = item.section_name || "Default Section";

    if (!acc[sectionId]) {
      acc[sectionId] = {
        key: keyCounter.toString(),
        section_id: sectionId,
        section_name: sectionName,
        attributes: [],
      };
      keyCounter++;
    }
    const { section_id, section_name, ...attribute } = item;
    attribute?.hasOwnProperty("attributes")
      ? (acc[sectionId].attributes = attribute?.attributes)
      : acc[sectionId].attributes.push(attribute);
    return acc;
  }, {});

  const updatedFilterList = Object.values(groupedData).map((item) => ({
    ...item,
    key: item.key,
    attributes: item.attributes,
  }));

  return updatedFilterList;
};

function TechnicianChecklist2({
  get_status,
  ticket_id,
  machine_id,
  ticket_status,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");

  const handle_add_name = (e) => {
    set_add_name(e.target.value);
  };

  const handle_submit_add_name = () => {
    if (add_index === "1") {
      add_action_name(success_config, {
        action_name: add_name,
      });
    }
  };

  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (add_index === "1") {
        get_action_name(success_get_action_name);
      }
      setIsModalOpen(false);
      set_add_name("");
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    set_add_name("");
    set_add_index("");
  };

  // -----------------------------------------------------------------------

  const navigate = useNavigate();
  const [value_list, set_value_list] = useState([]);
  const [attribute_details, set_attribute_details] = useState([]);
  const [action_name_list, set_action_name_list] = useState([]);
  const [action, set_action] = useState([]);
  const [used_spare_list, set_used_spare_list] = useState([]);

  useEffect(() => {
    get_action_name(success_get_action_name);
    get_final_checklist_details(success_get_final_checklist_details, {
      machine_id: machine_id,
      ticket_id: ticket_id,
    });
    get_spare_details_by_machine(success_get_spare_part_inventory, {
      machine_id: machine_id,
    });
    get_spare_detail_by_ticket(success_get_spare_detail_by_ticket, {
      ticket_id: ticket_id,
    });
  }, [machine_id, ticket_id]);

  const success_get_final_checklist_details = (res) => {
    res?.data?.data?.length === 0 &&
      save_final_checklist_details(success, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    set_attribute_details(
      res.data.data?.sort((a, b) => a.section_id - b.section_id)
    );
    set_value_list(res.data.data?.map(() => ""));
    set_action(res.data.data?.map(() => ""));
  };

  const success_get_action_name = (res) => {
    set_action_name_list(res.data.data);
  };

  const handleAction = (e, index) => {
    const temp = [...action];
    temp[index] = e.target.value;
    set_action(temp);
  };

  const handleValue = (value, index) => {
    const temp = [...value_list];
    temp[index] = value;
    set_value_list(temp);
  };

  const success = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_final_checklist_details(success_get_final_checklist_details, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    } else if (res.status === "failed") {
      toast.warning("There are no attributes associated with machine");
    }
  };

  const success_ticket_status_update = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      handlePause();
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const success_add_machine_action_details = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      get_final_checklist_details(success_get_final_checklist_details, {
        machine_id: machine_id,
        ticket_id: ticket_id,
      });
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  const handlePause = () => {
    const time = dayjs.utc().add(5, "hour").add(30, "minute");
    save_machine_time_record(
      () => {
        window.location.reload();
      },
      {
        ticket_id: ticket_id,
        machine_id: machine_id,
        end_time: time,
      }
    );
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    console?.log(file);
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file?.originFileObj);
    }
    console.log(file?.preview);
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
    );
  };

  const columns = [
    {
      title: () => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Specification
        </span>
      ),
      key: "specification",
      width: "35%",
      render: (_, record) =>
        record?.attribute_name + (record?.unit ? `(${record?.unit})` : ""),
    },
    {
      title: "Expected Values",
      key: "specification",
      width: "12%",
      align: "center",

      render: (_, record) => {
        return (
          <>
            {record?.data_type?.toLowerCase() === "string" && record?.value}
            {record?.data_type?.toLowerCase() === "integer" &&
              `${record?.min_value} - ${record?.max_value}`}
            {record?.data_type?.toLowerCase() === "boolean" &&
              `${record?.bool_value}`}
          </>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (_, row) => {
        return (
          row?.image_status && (
            // <IconButton
            //   color="success"
            //   onClick={() => {
            //     setPreviewImage(loading_image);
            //     setPreviewOpen(true);
            //     get_api_function(
            //       `/pm/checklist_image?checklist_id=${row?.attribute_value_id}`,
            //       (res) => {
            //         if (res.data.status === "success") {
            //           const imageGetData = res.data.data[0];
            //           setPreviewImage(
            //             `data:image/png;base64,${imageGetData.image1}`
            //           );
            //           setPreviewOpen(true);
            //         } else {
            //           setPreviewOpen(false);
            //           toast.warning("Failed to fetch image");
            //         }
            //       }
            //     );
            //   }}
            // >
            //   <VisibilityIcon color="success" />
            // </IconButton>
            <Image
              height="30px"
              width="45px"
              src={`data:image/png;base64,${row?.image1}`}
            />
          )
        );
      },
    },
    {
      title: "Actual Values",
      width: "15%",
      key: "value",
      align: "center",
      render: (_, record, index) => {
        return record?.user_value === "" &&
          record?.user_value_status === null ? (
          record?.data_type === "Boolean" || record?.data_type === "boolean" ? (
            <Radio.Group
              onChange={({ target: { value } }) => {
                handleValue(value, index);
              }}
            >
              <Radio value="true">True</Radio>
              <Radio value="false">False</Radio>
            </Radio.Group>
          ) : (
            <OutlinedInput
              id="value"
              type="text"
              placeholder="Enter Value"
              value={value_list[index]}
              onChange={(e) => {
                handleValue(e?.target?.value, index);
              }}
              fullWidth
            />
          )
        ) : (
          record?.user_value
        );
      },
    },
    {
      title: "Validity Check",
      key: "action",
      align: "center",
      width: "8%",
      render: (_, record, index) => {
        return record?.user_value_status === true ? (
          <Tooltip title="In Range">
            <ThumbUpIcon style={{ color: "green" }} />
          </Tooltip>
        ) : record?.user_value_status === false ? (
          <Tooltip title="Out of Range">
            <ThumbDownIcon style={{ color: "red" }} />
          </Tooltip>
        ) : (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Tooltip title="Approve">
              <IconButton
                size="small"
                // disabled={value_list[index].length === 0}
                onClick={() => {
                  update_checklist_details(success, {
                    attribute_value_id: record?.attribute_value_id,
                    machine_id: machine_id,
                    ticket_id: ticket_id,
                    user_value: value_list[index],
                    user_value_status: true,
                  });
                }}
              >
                <CheckIcon
                  style={{
                    color: "#00C853",
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                size="small"
                // disabled={value_list[index].length === 0}
                onClick={() => {
                  update_checklist_details(success, {
                    attribute_value_id: record?.attribute_value_id,
                    machine_id: machine_id,
                    ticket_id: ticket_id,
                    user_value: value_list[index],
                    user_value_status: false,
                  });
                }}
              >
                <CloseIcon
                  style={{
                    color: "red",
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      title: () => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Action
        </span>
      ),
      key: "action",
      width: "25%",
      // onCell: (record, index) => ({
      //   colSpan: record?.action_status === null ? 1 : 0,
      // }),
      render: (_, record, index) => {
        return record?.user_value_status === false &&
          record?.action_name === "" ? (
          <Stack direction="row" alignItem="center">
            <Select
              labelId="action"
              value={action[index]}
              onChange={(e) => {
                handleAction(e, index);
              }}
              required
              fullWidth
              size="small"
              MenuProps={{
                PaperProps: { sx: { maxHeight: 400 } },
              }}
            >
              {action_name_list.map((item) => {
                return (
                  <MenuItem value={item.action_id}>{item.action_name}</MenuItem>
                );
              })}
            </Select>
            <Tooltip title="Add Action">
              <IconButton
                onClick={() => {
                  setIsModalOpen(true);
                  set_add_index("1");
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          record?.action_name
        );
      },
    },
    {
      title: "Status",
      key: "action",
      align: "center",
      width: "8%",

      render: (_, record, index) => {
        return (
          record?.user_value_status === false &&
          (record?.action_status === true ? (
            <span
              style={{
                color: "#00C853",
              }}
            >
              Resolved
            </span>
          ) : record?.action_status === false ? (
            <span
              style={{
                color: "red",
              }}
            >
              Not Resolved
            </span>
          ) : (
            <Stack direction="row" justifyContent="center" spacing={1}>
              <Tooltip title="Resolved">
                <IconButton
                  size="small"
                  disabled={action[index] === ""}
                  onClick={() => {
                    save_checklist_action_details(
                      success_add_machine_action_details,
                      {
                        machine_id: machine_id,
                        ticket_id: ticket_id,
                        action_id: action[index],
                        action_status: true,
                        attribute_value_id: record?.attribute_value_id,
                      }
                    );
                  }}
                >
                  <CheckIcon
                    style={{
                      color: "#00C853",
                      fontSize: "28px",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Not Resolved">
                <IconButton
                  size="small"
                  disabled={action[index] === ""}
                  onClick={() => {
                    save_checklist_action_details(
                      success_add_machine_action_details,
                      {
                        machine_id: machine_id,
                        ticket_id: ticket_id,
                        action_id: action[index],
                        action_status: false,
                        attribute_value_id: record?.attribute_value_id,
                      }
                    );
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "red",
                      fontSize: "28px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          ))
        );
      },
      onCell: (record, index) => ({
        colSpan: record?.action_status === null ? 1 : 2,
      }),
    },
  ];

  const [spare_use, set_spare_use] = useState([]);
  const [spare_parts_list, set_spare_parts_list] = useState([]);
  const success_get_spare_part_inventory = (res) => {
    set_spare_parts_list(res.data.data);
  };

  const success_get_spare_detail_by_ticket = (res) => {
    set_used_spare_list(res.data.data);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal = () => {
    setIsModalOpen2(true);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const [spare_part, set_spare_part] = useState("");
  const [quantity, set_quantity] = useState("");
  const handle_spare_part = (e) => {
    set_spare_part(e.target.value);
  };
  const [error, set_error] = useState(false);
  const handle_quantity = (e) => {
    if (
      e.target.value <=
      spare_parts_list?.find((item) => item.spare_part_id === spare_part)
        ?.spare_quantity
    ) {
      if (parseInt(e.target.value) === 0) {
        set_error("Quantity cannot be 0");
      } else {
        set_quantity(parseInt(e.target.value));
        set_error("");
      }
    } else {
      set_error("Quantity exceeds available inventory spare quantity");
    }
  };
  const handle_spare_use = (e) => {
    e.preventDefault();
    const temp = [...spare_use];
    temp.push({
      spare_inventry_id: spare_part,
      quantity: quantity,
    });
    set_spare_use(temp);
    handleCancel2();
  };

  function filterSpareUse(spare_parts_list, spare_use) {
    var usedSparePartIds = new Set(
      spare_use.map(function (use) {
        return use.spare_part_id;
      })
    );
    return spare_parts_list.filter(function (sparePart) {
      return !usedSparePartIds.has(sparePart.spare_part_id);
    });
  }
  const [current_expand, set_current_expand] = useState([]);

  const handle_expand = (expanded, record) => {
    if (expanded) {
      set_current_expand([record?.key]);
    } else {
      set_current_expand([]);
    }
  };

  const expandedRowRender = (record) => {
    const updateData = record?.attributes?.map((item, index) => {
      return {
        ...item,
        key: index.toString(),
      };
    });

    return !["Completed", "Closed", "Verified", "Resolved"].includes(
      ticket_status
    ) && window.location.hash !== "#/maintenance-data" ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 30 }}
        description={
          <Stack spacing={1} direction="row" justifyContent="center">
            <span>Ticket is not yet resolved</span>
          </Stack>
        }
        style={{
          margin: "0",
        }}
      />
    ) : (
      <Table
        columns={columns}
        dataSource={updateData?.sort(
          (a, b) => a.attribute_value_id - b.attribute_value_id
        )}
        pagination={false}
        size="small"
        bordered
      />
    );
  };

  return (
    <React.Fragment>
      {previewImage && (
        <ConfigProvider
          theme={{
            components: {
              Image: {
                zIndexPopup: 2000,
              },
            },
          }}
        >
          <Image
            wrapperStyle={{
              display: "none",
              height: "100%",
            }}
            style={{ height: "100%" }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              // afterOpenChange: (visible) =>
              //   !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        </ConfigProvider>
      )}
      <>
        {!["Completed", "Closed", "Verified", "Resolved"].includes(
          ticket_status
        ) && window.location.hash !== "#/maintenance-data" ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 30 }}
            description={
              <Stack spacing={1} direction="row" justifyContent="center">
                <span>Ticket is not yet resolved</span>
              </Stack>
            }
            style={{
              margin: "0",
            }}
          />
        ) : (
          <>
            <Table
              dataSource={
                attribute_details.some((obj) =>
                  obj.hasOwnProperty("section_name")
                )
                  ? addKeysInFilterDataForCollapse(attribute_details)?.sort(
                      (a, b) => a.attribute_value_id - b.attribute_value_id
                    )
                  : attribute_details?.sort(
                      (a, b) => a.attribute_value_id - b.attribute_value_id
                    )
              }
              columns={
                attribute_details.some((obj) =>
                  obj.hasOwnProperty("section_name")
                )
                  ? [
                      {
                        dataIndex: "section_name",
                        title: "Section",
                        key: "section_name",
                      },
                    ]
                  : columns
              }
              scroll={{ x: 100, y: 480 }}
              bordered
              pagination={false}
              size="small"
              expandable={
                attribute_details.some((obj) =>
                  obj.hasOwnProperty("section_name")
                )
                  ? {
                      expandedRowRender,
                      expandedRowKeys: current_expand,
                      onExpand: handle_expand,
                    }
                  : {}
              }
            />

            <Descriptions
              column={3}
              size="middle"
              contentStyle={{}}
              labelStyle={{
                fontWeight: "800",
                width: "16.5%",
              }}
              bordered
              items={[
                {
                  key: 1,
                  label: "Spares Used",
                  children:
                    used_spare_list?.length === 0 ? (
                      !["Completed", "Closed", "Verified", "Resolved"].includes(
                        ticket_status
                      ) ? (
                        <React.Fragment>
                          {spare_use?.map((item) => (
                            <Chip
                              sx={{ m: 0.5 }}
                              label={`${
                                spare_parts_list?.find(
                                  (spare) =>
                                    spare.spare_part_id ===
                                    item?.spare_inventry_id
                                )?.spare_part_name
                              }
                    - ${item?.quantity}`}
                              variant="outlined"
                              onDelete={() => {
                                const temp = [...spare_use];
                                const temp2 = temp.filter(
                                  (obj) =>
                                    obj.spare_inventry_id !==
                                    item?.spare_inventry_id
                                );
                                set_spare_use(temp2);
                              }}
                            />
                          ))}

                          <IconButton size="small" onClick={showModal}>
                            <AddIcon color="primary" />
                          </IconButton>
                        </React.Fragment>
                      ) : (
                        "No Spare Parts Used"
                      )
                    ) : (
                      used_spare_list?.map((item) => (
                        <Chip
                          sx={{ m: 0.5 }}
                          label={`${item?.spare_name}
                    - ${item?.spare_quantity}`}
                          variant="outlined"
                        />
                      ))
                    ),
                },
              ]}
            />
          </>
        )}
      </>

      {window.location.hash === "#/maintenance-data" && get_status && (
        <Stack direction="row" justifyContent="end" sx={{ my: 2 }}>
          <Button
            variant="contained"
            disabled={
              attribute_details?.some((obj) =>
                obj.hasOwnProperty("section_name")
              )
                ? addKeysInFilterDataForCollapse(attribute_details)?.some(
                    (section) =>
                      section?.attributes?.some(
                        (attribute) => attribute.user_value_status === null
                      )
                  )
                : attribute_details?.some(
                    (item) => item?.user_value_status === null
                  )
            }
            onClick={() => {
              update_maintenace_ticket_status(success_ticket_status_update, {
                machine_id: machine_id,
                ticket_id: ticket_id,
              });
              maintenace_ticket_use(success_ticket_status_update, {
                machine_id: machine_id,
                ticket_id: ticket_id,
                spare_list: spare_use,
              });
            }}
          >
            Submit
          </Button>
        </Stack>
      )}

      <Modal
        title={`Add ${add_index === "1" && "Action"}`}
        open={isModalOpen}
        okButtonProps={{ disabled: add_name.length === 0 }}
        onOk={handle_submit_add_name}
        onCancel={handleCancel}
        okText="Add"
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            <InputLabel id="name">
              {`${add_index === "1" && "Action"} : `}
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={6}>
            <OutlinedInput
              id="name"
              type="text"
              name="text"
              size="small"
              placeholder={`Enter ${add_index === "1" && "Action"}`}
              fullWidth
              required
              value={add_name}
              onChange={handle_add_name}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title="Spare Parts Used"
        open={isModalOpen2}
        onCancel={handleCancel2}
        footer={[]}
        afterClose={() => {
          set_spare_part("");
          set_quantity("");
        }}
      >
        <form onSubmit={handle_spare_use}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required id="spare_part">
                  Spare Part
                </InputLabel>
                <Select
                  labelId="spare_part"
                  onChange={handle_spare_part}
                  value={spare_part}
                  required
                  MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
                >
                  {filterSpareUse(spare_parts_list, spare_use)?.map((item) => {
                    return (
                      <MenuItem value={item?.spare_part_id}>
                        {item?.spare_part_name} &nbsp; - &nbsp;
                        {item?.manufacturer_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Grid>
            <Grid item lg={12}>
              <Stack spacing={1}>
                <InputLabel required htmlFor="quantity">
                  Quantity
                </InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="number"
                  name="quantity"
                  placeholder="Enter quantity"
                  fullWidth
                  required
                  value={quantity}
                  onChange={handle_quantity}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                        },
                    },
                  }}
                />
                {error?.length > 0 && (
                  <FormHelperText error={error?.length > 0}>
                    {error}
                  </FormHelperText>
                )}
                {spare_part && (
                  <FormHelperText>
                    Available Inventory Spare Quantity:{" "}
                    {
                      spare_parts_list?.find(
                        (item) => item.spare_part_id === spare_part
                      )?.spare_quantity
                    }
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item lg={12} spacing={2}>
              <Stack spacing={2} direction="row" justifyContent="end">
                <Button variant="outlined" size="small" onClick={handleCancel2}>
                  Cancel
                </Button>
                <Button variant="contained" size="small" type="submit">
                  Add
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default TechnicianChecklist2;

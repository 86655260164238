import React, { useEffect, useState } from "react";

import { Timeline, Image, Descriptions } from "antd";
import MainCard from "../../../components/MainCard";
import { Stack, Button, Grid, Tooltip } from "@mui/material";
import {
  get_all_machineDetails,
  get_api_function,
  get_flow_details,
  get_instruction_status,
} from "utils/api";
import {} from "utils/api";
import { toast } from "react-toastify";
import { Drawer as Drawer1, Tabs } from "antd";

import {
  QuestionCircleOutlined,
  DollarOutlined,
  MenuOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { FloatButton } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Timer from "../StopWatch";
import TechnicianChecklist2 from "../machine-details/TechnicianChecklist2";
import CostInvolved from "../ticket-details/CostInvolved";
import ActionInTicket from "../ticket-details/ActionInTicket";
import TimeLineData from "../ticket-details/TimeLine";
import ChatBox from "../ticket-details/ChatBox";
import TimeHistory from "../ticket-details/TimeHistory";
import WorkHistory from "../ticket-details/WorkHistory";
import TicketCard from "./TicketCard";

function MaintenanceTicket() {
  const use_location = useLocation();
  const navigate = useNavigate();

  const machine_id = use_location?.state?.machine_id;
  const ticket_id = use_location?.state?.ticket_id;

  const [machine_details, set_machine_details] = useState([]);

  const [ticket_details, set_ticket_details] = useState(null);
  const [ticket_flow, set_ticket_fllow] = useState([]);
  const [unique_id, set_unique_id] = useState("");
  const [transaction_id, set_transaction_id] = useState("");

  const [timer_history, set_timer_history] = useState([]);
  const [is_started, set_is_started] = useState(false);
  const [time_history_refresh, set_time_history_refresh] = useState(false);
  const [show_checklist, set_show_checklist] = useState(false);
  const [float_open, set_float_open] = useState(true);
  const [open, setOpen] = useState(false);
  const [open_drawer, set_open_drawer] = useState();

  useEffect(() => {
    get_flow_details(success_get_flow_details, ticket_id);
    get_all_machineDetails(success_get_machineDetails, {
      machine_id: machine_id,
    });
    get_instruction_status(success_set_instructions_list, ticket_id);
  }, [machine_id, ticket_id]);

  useEffect(() => {
    get_api_function(
      `/pm/show_ticket_time_history?machine_id=${machine_id}&ticket_id=${ticket_id}`,
      success_get_timer_history
    );
  }, [time_history_refresh, machine_id, ticket_id]);

  const success_get_timer_history = (res) => {
    if (res.data.status === "success") set_timer_history(res?.data?.data[0]);
  };

  const success_get_flow_details = (res) => {
    if (res.data.status === "success") {
      set_ticket_details(res?.data?.data[0].Main_details[0]);
      set_unique_id(res?.data?.data[0].ticket_unique_id);
      set_transaction_id(res?.data?.data[0].transaction_id);
      set_ticket_fllow(res?.data?.data[0].Flow1);
    }
  };

  const success_get_machineDetails = (res) => {
    set_machine_details(res.data.data[0]);
  };

  const [instrutions_list, set_instructions_list] = useState([]);

  const success_set_instructions_list = (res) => {
    set_instructions_list(res.data.data);
    set_show_checklist(
      res.data.data?.some(
        (instruction) => instruction.instruction_name === "Completed"
      )
    );
  };

  return (
    <Grid container>
      <FloatButton.Group
        trigger="click"
        type="primary"
        open={float_open}
        onClick={() => {
          set_float_open(!float_open);
        }}
        style={{
          right: open ? 524 : 24,
        }}
        icon={<MenuOutlined />}
      >
        <Tooltip title="Work History">
          <FloatButton
            icon={<WorkHistoryOutlinedIcon fontSize="small" />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Work History");
            }}
          />
        </Tooltip>
        <Tooltip title="Time History">
          <FloatButton
            icon={<HistoryOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Time History");
            }}
          />
        </Tooltip>
        <Tooltip title="Cost Involved">
          <FloatButton
            icon={<DollarOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Cost Involved");
            }}
          />
        </Tooltip>
        <Tooltip title="Support">
          <FloatButton
            icon={<QuestionCircleOutlined />}
            type="primary"
            onClick={() => {
              setOpen(!open);
              set_open_drawer("Support");
            }}
          />
        </Tooltip>
      </FloatButton.Group>

      <Grid item xs={12} lg={9}>
        <MainCard
          title="Ticket Details"
          secondary={
            <Timer
              set_is_started={set_is_started}
              is_started={is_started}
              get_status={instrutions_list?.some(
                (instruction) => instruction.instruction_name === "Completed"
              )}
              ticket_id={ticket_id}
              machine_id={machine_id}
              set_time_history_refresh={set_time_history_refresh}
            />
          }
        >
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Descriptions
                column={3}
                size="middle"
                contentStyle={{}}
                labelStyle={{
                  fontWeight: "800",
                }}
                bordered
                items={[
                  ticket_details?.Summary && {
                    key: 2,
                    label: "Title",
                    children: ticket_details?.Summary,
                  },
                  unique_id && {
                    key: 3,
                    label: "Ticket Id",
                    children: unique_id,
                  },
                  machine_details?.name && {
                    key: 1,
                    label: "Asset Name",
                    children: machine_details?.name,
                  },
                  machine_details?.assert_no && {
                    key: 4,
                    label: "Asset No",
                    children: machine_details?.assert_no,
                  },
                  machine_details?.installation_date?.slice(0, 19) && {
                    key: 5,
                    label: "Installation Date",
                    children: machine_details?.installation_date?.slice(0, 19),
                  },
                  machine_details?.line_name && {
                    key: 6,
                    label: "Line",
                    children: machine_details?.line_name,
                  },
                  machine_details?.location && {
                    key: 7,
                    label: "Location",
                    children: machine_details?.location,
                  },
                  machine_details?.tester_name && {
                    key: 8,
                    label: "Tester Name",
                    children: machine_details?.tester_name,
                  },
                  machine_details?.model && {
                    key: 9,
                    label: "Model",
                    children: machine_details?.model,
                  },
                  machine_details?.machine_year && {
                    key: 10,
                    label: "Asset Year",
                    children: machine_details?.machine_year,
                  },
                  machine_details?.software_details && {
                    key: 11,
                    label: "Software Details",
                    children: machine_details?.software_details,
                  },
                  machine_details?.machine_type && {
                    key: 12,
                    label: "Asset Type",
                    children: machine_details?.machine_type,
                  },
                  machine_details?.frequency && {
                    key: 13,
                    label: "PM Frequency",
                    children: machine_details?.frequency,
                  },
                  machine_details?.due_date?.slice(0, 19) && {
                    key: 14,
                    label: "PM Date",
                    children: machine_details?.due_date?.slice(0, 19),
                  },
                  {
                    key: 14,
                    label: "History",
                    children: (
                      <Button
                        size="small"
                        onClick={() =>
                          navigate("/report", {
                            state: {
                              machine_id: machine_id,
                            },
                          })
                        }
                      >
                        Check history
                      </Button>
                    ),
                  },
                ]?.filter(Boolean)}
              />
            </Grid>
          </Grid>
        </MainCard>
        {is_started &&
          (ticket_details?.ticket_status === "Completed" ||
            ticket_details?.ticket_status === "Closed" ||
            ticket_details?.ticket_status === "Verified" ||
            ticket_details?.ticket_status === "Resolved" ||
            instrutions_list?.some(
              (instruction) => instruction.instruction_name === "Completed"
            )) && (
            <MainCard title="PM Checklist">
              <TicketCard
                get_status={instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                )}
                ticket_id={ticket_id}
                machine_id={machine_id}
                ticket_status={ticket_details?.ticket_status}
                complete_status={
                  !(
                    ticket_details?.ticket_status === "Completed" ||
                    ticket_details?.ticket_status === "Closed" ||
                    ticket_details?.ticket_status === "Verified" ||
                    ticket_details?.ticket_status === "Resolved"
                  )
                }
              />
            </MainCard>
          )}
      </Grid>
      <Grid container item xs={12} lg={3}>
        <MainCard
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Tabs
            tabBarExtraContent={
              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            }
            items={[
              instrutions_list?.length > 0 &&
                !instrutions_list?.some(
                  (instruction) => instruction.instruction_name === "Completed"
                ) && {
                  key: "1",
                  label: "Action",
                  children: (
                    <ActionInTicket
                      transaction_id={transaction_id}
                      instrutions_list={instrutions_list}
                      machine_id={machine_details?.machine_id}
                    />
                  ),
                },
              {
                key: "2",
                label: "Timeline",
                children: <TimeLineData flow_data={ticket_flow} />,
              },
            ].filter(Boolean)}
          />
        </MainCard>
      </Grid>

      <Drawer1
        title={open_drawer}
        placement="right"
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        zIndex={2147}
      >
        {open_drawer === "Time History" && (
          <TimeHistory time_history={timer_history?.time_history} />
        )}
        {open_drawer === "Work History" && (
          <WorkHistory work_history={timer_history} />
        )}
        {open_drawer === "Cost Involved" && (
          <CostInvolved history_ticket_id={ticket_id} />
        )}
        {open_drawer === "Support" && (
          <ChatBox
            params={{
              asset_type: machine_details?.machine_type,
              asset_model: machine_details?.model,
              asset_make: machine_details?.make,
              issue_type: ticket_details?.issue_type,
            }}
          />
        )}
      </Drawer1>
    </Grid>
  );
}

export default MaintenanceTicket;
